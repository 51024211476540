<template>
    <div class="demo-image" v-show="version">
      <div class="block">
        <el-image
            style="width: 100%; height: 100%"
            src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/openWeb.jpg"
            fit="fill"></el-image>
      </div>
      <a href="http://www.isasaerp.com/android/isasa.apk" v-show="!version" id="download"></a>
    </div>

</template>

<script>
import openWeb from "@/assets/img/openWeb.jpg"
export default {
  name: "android-down",
  data(){
    return{
      img:openWeb,
      version:true
    }
  },
  mounted() {
    this.isX5();
  },
  methods: {
    isX5() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      const ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      //判断是否是微信  如果是微信则显示图片
      this.version = /MicroMessenger/i.test(ua);
      if (!this.version){
        document.getElementById("download").click();
        // window.open("http://www.isasaerp.com/android/isasa.apk", '_self');
        // window.location.href("http://www.isasaerp.com/android/isasa.apk");
      }
    }
  }
}
</script>

<style scoped>

</style>